<template>
  <div class="header_box">
    <div class="header_"></div>
    <div
      class="header"
      ref="header"
      :class="navBarFixed ? 'fix-nav scale-up-ver-top' : ''"
    >
      <div class="header_top">
        <div class="logo">
          <img src="../assets/logo.png" alt="" />
        </div>
        <div class="nav">
          <ul>
            <li v-for="(nav, index) in navArr" :key="index">
              <div class="title" @click="navClick(nav.router)">
                {{ nav.title }}
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="header_sm">
        <div class="logo">
          <img src="../assets/logo.png" alt="" />
        </div>
        <div class="icon">
          <i
            :class="navShow ? 'el-icon-close' : 'el-icon-s-operation'"
            @click="showNav()"
          ></i>
        </div>
        <el-collapse-transition>
          <div class="nav_sm" v-show="navShow">
            <ul>
              <li
                v-for="(nav, index) in navArr"
                :key="index"
                @click="navClick(nav.router)"
              >
                <div class="title">
                  {{ nav.title }}
                </div>
              </li>
            </ul>
          </div>
        </el-collapse-transition>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // src: require('/src/assets/logo.png'),
      navArr: [
        { title: "首页", router: "/" },
        { title: "物业管理", router: "/manage" },
        { title: "物业SCRM", router: "/scrm" },
        { title: "智慧社区", router: "/intelligence" },
        { title: "硬件支持", router: "/hardware" },
        // { title: "关于我们", router: "/produce" },
        // { title: "免费试用", router: "/contact" },
      ],
      navShow: false,
      navBarFixed: false,
    };
  },
  created() {
    document.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    navClick(router) {
      console.log(router);
      this.navShow = false;
      this.$router.push(router);
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth", // 可选，平滑滚动
      });
    },
    showNav() {
      if (this.navShow) {
        console.log(1);
      }
      this.navShow = !this.navShow;
    },
    handleScroll() {
      // console.log(this.$refs.header.offsetHeight)
      // 滚动的距离
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // 容器的高度
      const offsetTop = this.$refs.header.offsetHeight;

      //  滚动的距离如果大于了元素到顶部的距离时，实现吸顶效果
      if (scrollTop > offsetTop) {
        this.navBarFixed = true;
      } else {
        this.navBarFixed = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@keyframes scale-up-ver-top {
  0% {
    transform: scaleY(0.4);
    transform-origin: 100% 0%;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 100% 0%;
  }
}
@media screen and (min-width: 992px) {
  .scale-up-ver-top {
    animation: scale-up-ver-top 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  }
  .fix-nav {
    position: fixed !important;
    background: rgba(0, 0, 0, 0.8);
  }
  .header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;
    img {
      width: 100%;
    }
    .header_sm {
      display: none;
    }
    .header_top {
      width: 72rem;
      height: 4rem;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .logo {
        width: 6rem;
        height: 1.72rem;
        img {
          width: 100%;
          height: 100%;
        }
      }
      ul {
        display: flex;
        li {
          display: flex;
          align-items: center;
          .title {
            color: #fff;
            margin: 0 1rem;
            font-size: 0.8rem;
            cursor: pointer;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 992px) {
  .header_ {
    height: 12vw;
  }
  .header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;
    img {
      width: 100%;
    }
    .header_top {
      display: none;
    }
    .header_sm {
      display: flex;
      height: 12vw;
      background: #343434;
      align-items: center;
      justify-content: space-between;
      padding: 0 4vw;
      .logo {
        width: 24vw;
        height: 8vw;
        img {
          width: 100%;
          height: 100%;
          object-fit: scale-down;
        }
      }
      .icon {
        color: #fff;
        font-size: 6vw;
      }
      .nav_sm {
        position: fixed;
        top: 12vw;
        left: 0;
        right: 0;
        background: #fff;
        z-index: 9;
        ul {
          height: calc(100vh - 12vw);
          display: flex;
          flex-direction: column;
          li {
            display: flex;
            align-items: center;
            .title {
              padding: 4vw 4vw;
              font-size: 1vw;
              cursor: pointer;
              font-size: 4vw;
            }
          }
        }
      }
    }
  }
}
</style>
