<template>
  <div id="app">
    <Nav></Nav>
    <router-view/>
    <Bottom></Bottom>
  </div>
</template>

<script>
import Nav from '@/components/Nav.vue'
import Bottom from '@/components/Bottom.vue'

export default {
  components: {
    Nav,
    Bottom
  }
}
</script>

<style lang="scss">
*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  // font-family:"SimSun","Microsoft YaHei";
  font-family:"Microsoft YaHei";
}
img{
  display: flex;
}
ul,li{
  list-style: none;
}
@media screen and (min-width: 992px) {
  html{
    font-size: calc(100vw/1920*20);
    .qkl_tips{
      font-size: 0.8rem;
      color: #1072d5;
    }
    .qkl_title{
      font-size: 2rem;
      color: #202020;
    }
    .qkl_p{
      font-size: 1rem;
      color: #888;

    }
  }
}
@media screen and (max-width: 992px) {
  .qkl_tips{
    font-size: 3vw;
    color: #1072d5;
  }
  .qkl_title{
    font-size: 6vw;
    color: #202020;
  }
  .qkl_p{
    font-size: 4vw;
    color: #888;

  }
}
// @media screen and (min-width: 1200px) and (max-width: 1600px) {
//   html{
//     font-size: 16px;
//   }
// }
// @media screen and (min-width: 992px) and (max-width: 1200px) {
//   html{
//     font-size: 12px;
//   }
// }
</style>
